<template>
  <div class="overflow-auto">
    <div class="flex items-center font-semibold text-black text-xl px-6 mt-6">
      <div>Part Manuals</div>

      <div class="relative ml-8 w-1/3">
        <input
          v-model="partManualSearchValue"
          @keydown="handleSearchKeyPress"
          @keyup="handleSearchKeyUp"
          class="
            w-full
            bg-gray-100
            h-10
            px-5
            pr-16
            rounded-lg
            text-sm
            focus:outline-none
          "
          type="search"
          name="search"
          placeholder="Search"
        />
        <button type="submit" class="absolute top-0 right-0 px-3 py-3">
          <svg
            class="text-gray-600 h-4 w-4 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 56.966 56.966"
            style="enable-background: new 0 0 56.966 56.966"
            xml:space="preserve"
            width="512px"
            height="512px"
          >
            <path
              d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
            />
          </svg>
        </button>
      </div>
    </div>

    <div class="px-6 mt-2 mb-8 text-sm">
      <h1>
        This is our library of part manuals and documentations. Please use any
        keywords to search for part manuals.
      </h1>
    </div>
    <router-view v-if="!partManualSearchValue"></router-view>
    <div
      v-if="partManualSearchValue && $store.getters.partManuals"
      class="mx-6 flex items-center py-1 px-3 bg-gray-100 rounded font-bold"
    >
      Search results for "{{ partManualSearchValue }}"
    </div>
    <p
      v-if="
        partManualSearchValue &&
        (!$store.getters.partManuals || !$store.getters.partManuals.length)
      "
      class="my-6 px-6"
    >
      Looks like there is nothing matching your search. Please try again.
    </p>
    <div
      v-if="partManualSearchValue && $store.getters.partManuals"
      class="grid grid-cols-8 p-5 gap-5"
    >
      <!-- <button
      @click="
        folderStack.push(folder),
          getManualFolders({
            brand: this.selectedBrand._id,
            folder: folderStack[folderStack.length - 1]._id,
          })
      "
      v-for="folder in manualFolders"
      :key="folder._id"
      class="
        py-6
        cursor-pointer
        z-10
        relative
        bg-gray-200
        text-center
        rounded-2xl
        transform
        hover:shadow-xl hover:-translate-y-1 hover:scale-108
        duration-300
        ease-in-out
      "
    >
      <p>
        <svg
          class="mx-auto mb-4"
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M11 21H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h5l2 3h9a2 2 0 0 1 2 2v2M19 15v6M16 18h6"
          />
        </svg>
      </p>
      <h1 class="uppercase text-black font-bold text-xs py-1">
        {{ folder.name }}
      </h1>
    </button> -->
      <a
        v-for="manual in partManuals"
        :key="manual._id"
        :href="`${api_host}/uploads/${manual.file}`"
        target="_blank"
        class="
          py-6
          cursor-pointer
          z-10
          relative
          bg-gray-200
          text-center
          rounded-2xl
          transform
          hover:shadow-xl hover:-translate-y-1 hover:scale-108
          duration-300
          ease-in-out
        "
      >
        <p>
          <svg
            class="mx-auto mb-4"
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"
            />
            <path d="M14 3v5h5M16 13H8M16 17H8M10 9H8" />
          </svg>
        </p>
        <h1 class="uppercase text-black font-bold text-xs py-1">
          {{ manual.name }}
        </h1>
      </a>
    </div>
  </div>
</template>

<script>
import mutations from "@/utils/mutation-types";
import { mapGetters } from "vuex";

export default {
  name: "PartManuals",
  data() {
    return {
      searchTypingTimeout: 500,
      api_host: process.env.VUE_APP_API_HOST,
    };
  },
  methods: {
    handleSearchKeyPress() {
      window.clearTimeout(window.searchTimer);
      this.$store.commit(mutations.SET_LOADING, true);
    },
    handleSearchKeyUp() {
      window.clearTimeout(window.searchTimer);

      window.searchTimer = window.setTimeout(() => {
        let options = {};

        if (this.$route.params.brandid) {
          options = {
            brand: this.$route.params.brandid,
          };
        }

        this.$store.dispatch("getPartManuals", options);
        // console.log("test");
      }, this.searchTypingTimeout);
      // console.log(this.timer);
    },
  },
  computed: {
    ...mapGetters(["partManuals"]),
    partManualSearchValue: {
      get() {
        return this.$store.getters.partManualSearchValue;
      },
      set(val) {
        this.$store.commit(mutations.SET_PART_MANUAL_SEARCH_VAL, val);
      },
    },
  },
};
</script>


<style scoped>
</style>


